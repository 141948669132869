// Variables
$website-width: 900px;
$main-color: #e6b82d;
$light-color: #f4f4f4;
$medium-color: #ccc;
$background-color: green;
$font-color: #333;
$dark-color: #333;
$bg-image: url('https://cdn.pixabay.com/photo/2021/05/03/10/12/buttercup-6225833_960_720.jpg');

// Buttercup Color scheme //////////////////////////
$bg-image: url('https://papastefanou.com/wp-content/uploads/2021/05/buttercup.jpg');

$main-color: #478e12; // Slimy Green
$color2: #174603; // Lincoln Green
$color3: #ffd30b; // Cyber Yellow
$secondary-color: #f9c10a; // Golden Poppy
$background-color: #fffcf9; // Baby Powder

// Buttercup Garden Color Scheme //////////////////////////
// https://coolors.co/398201-3d405b-e6b82d-d54f54-34252f-cccccc
// Color definitions
$bg-image: url('https://papastefanou.com/wp-content/uploads/2021/05/buttercup.jpg');
$lincoln-green: #194b00ff;
$independence: #3d405b;
//$slimy-green: #498e0dff;
$olive-drab-3: #738c0c;
//$india-green: #398201;
//$cyber-yellow: #fed50aff;
$saffron: #e6b82d;
//$french-raspberry: #ba3b46ff;
$indian-red: #d54f54;
$black-coffee: #34252fff;
$baby-powder: #fffcf9;

// Color assignments
$main-color: $olive-drab-3;
$accent-color: $baby-powder;
$canvas-background-color: $black-coffee;
$body-background-color: $baby-powder;
$icon-color: $main-color;
$button-color: $saffron;
$light-text-color: $baby-powder;
$dark-text-color: $black-coffee;

//////////////////////////////////////////////

// Apple Bloom scheme
/* $bg-image: url('https://papastefanou.com/wp-content/uploads/2021/05/flora-3787896_1920.jpg'); */

// Misc
/* $bg-image: url('https://papastefanou.com/wp-content/uploads/2021/05/chocolate-5177588.jpg');
$header-font-color: #fff; */

// Links
$link-color: #1a5b9e;
$active-link-color: #10375e;

// Amelie color scheme
$blueish: #29325c;
$redish: #d54f54;
$lightgrayish: #dadcdc;
$greenish: #738c0c;
$yellowish: #e6b82d;
$azureish: #30b1c2;

// Set Text Color
@function set-text-color($color) {
  @if (lightness($color) > 50) {
    @return #000;
  } @else {
    @return #fff;
  }
}
