@import 'config';
@import 'utilities';
@import 'item_grid';
@import 'print_resume';

// General Styles
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: Roboto, sans-serif;
  line-height: 1.4;
  background: $canvas-background-color;
  color: $font-color;
}

section {
  background: $body-background-color;
}

a {
  text-decoration: none;
  color: $link-color;
}

a:hover {
  text-decoration: underline;
}

a:active {
  color: $active-link-color;
}

ul {
  list-style: none;
}

h1,
h2 {
  text-transform: uppercase;
  font-family: Dosis;
}

section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
}
h3 {
  font-size: 1rem;
}

img {
  width: 100%;
}

.app > div {
  position: relative;
}
// Main Nav
#main-nav {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;

  ul {
    display: flex;
  }

  li {
    padding: 1rem 1.5rem;

    a {
      @extend %header-text-shared;
      text-decoration: none;
      text-transform: uppercase;
      border-bottom: 3px transparent solid;
      padding-bottom: 0.5rem;
      transition: border-color 0.5s;

      &:hover {
        border-color: $secondary-color;
      }

      &.current {
        border-color: $secondary-color;
      }
    }
  }
}

//

%header-text-shared {
  color: #fff;
  //text-shadow: 0px 0px 6px #000;
}

%header-shared {
  @extend %header-text-shared;
  background-color: rgba(0, 0, 0, 0);
  background: $bg-image no-repeat center center / cover;
  height: 28rem;
  z-index: 10;
}

/* %header-before-shared {
  content: '';
  background: $bg-image no-repeat center center / cover;
  filter: blur(0px);
  top: 0;
  left: 0;
  right: 0;
  height: 28rem;
  position: absolute;
  z-index: -1;
} */

#header {
  // Header Home
  &-home {
    @extend %header-shared;

    #main-nav .logo a:hover,
    #main-nav .logo a:active {
      text-decoration: none;
    }

    // Header Content
    .header-content {
      text-align: center;
      padding: 5%;

      p.catch-phrase {
        font-family: Dosis, sans-serif;
        font-size: 3rem;
      }
    }
  }

  /*   &-home::before {
    @extend %header-before-shared;
  } */

  // Header Inner
  &-inner {
    @extend %header-shared;
    height: 6rem;
    border-bottom: 3px solid $main-color;

    .header-content {
      display: none;
    }
  }

  /*   &-inner::before {
    @extend %header-before-shared;
    height: 6rem;
  } */
}

// Logo
.logo {
  a {
    @extend %header-text-shared;
  }
  a:hover {
    color: #fff;
    text-decoration: none;
  }
}

// Home Sections
#home {
  // Home Section A
  &-a {
    .specials {
      margin-top: 3rem;
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(4, 1fr);

      .fas {
        color: $icon-color;
        padding-bottom: 0.4rem;
      }
    }
  }

  // Home Section B
  &-b {
    .stats {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      li {
        line-height: 2;

        &.stats-title {
          font-size: 1.5rem;
        }

        &.stats-number {
          font-size: 2rem;
          font-weight: bold;
        }
      }

      div {
        padding: 3rem 0;

        &:nth-child(odd) {
          background: $light-color;
        }

        &:nth-child(even) {
          background: $medium-color;
        }
      }
    }
  }

  // Home Section C
  &-c {
    .process {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1.5rem;
      text-align: center;

      // process-step
      &-step {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 28px;
        background: $main-color;
        color: set-text-color($main-color);
        border-radius: 50%;
        height: 15px;
        width: 15px;
        line-height: 15px;
        padding: 1rem;
        transition: all 1s;
      }

      // process-icon
      &-icon {
        border-radius: 50%;
        background: $dark-color;
        color: set-text-color($dark-color);
        padding: 2rem;
        width: 70px;
        height: 70px;
        line-height: 70px;
        text-align: center;
        position: relative;
        transition: all 1s;

        &:hover {
          background: $main-color;
          width: 90px;
          height: 90px;
          line-height: 90px;

          .process-step {
            background: $dark-color;
            color: set-text-color($dark-color);
          }
        }
      }
    }
  }
}

// About Sections
#about {
  // About A
  &-a {
    .about-info {
      display: grid;
      grid-template-areas:
        'bioimage bio bio'
        'aw1 aw2 aw3';
      grid-gap: 1.2rem;
    }

    // Grid Area
    .bio-image {
      grid-area: bioimage;
    }

    .bio {
      grid-area: bio;
      border-left: 3px solid $main-color;
      padding: 0.8rem;
    }

    .award-1 {
      grid-area: aw1;
    }

    .award-2 {
      grid-area: aw2;
    }

    .award-3 {
      grid-area: aw3;
    }

    .award-1 .fas,
    .award-2 .fas,
    .award-3 .fas {
      color: $main-color;
      margin: 1rem;
    }
  }
  // About B
  &-b {
    .progress {
      overflow: hidden;
      height: 20px;
      background: $medium-color;
      border-radius: 5px;
      margin-bottom: 0.6rem;

      div {
        height: 100%;
        color: #fff;
        text-align: center;
        background: $main-color;
      }
    }
  }
  // About C
  &-c {
    .about-logos {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 2rem;

      img {
        width: 70%;
      }
    }
  }
  // About D
  &-d {
    .testimonials {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 0.5rem;

      ul {
        list-style: none;
        margin-top: 1rem;
        display: flex;
        align-items: center;
      }

      p {
        border: 1px solid $medium-color;
        border-radius: 5px;
        padding: 0.5rem;
      }

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 0.5rem;
      }
    }
  }
}

// Resume Page
%resume-shared {
  h2 {
    margin-bottom: 30px;
  }
}

%icon-grid-shared {
  div > div > div {
    margin: 0 0 20px 0;
  }
}

%work-shared {
  div div {
    text-align: left;
    padding: 0.5rem;
  }

  div div:first-child {
    text-align: right;
  }

  p {
    margin-bottom: 10px;
  }
  li {
    display: inline-block;
    opacity: 0.6;
    word-wrap: normal;
  }

  li::after {
    content: '|';
    margin: 10px;
  }

  li:last-child:after {
    content: '';
    margin: 10px;
  }
}

#resume {
  &-work-experience {
    @extend %work-shared;
    background-color: $baby-powder;
    color: set-text-color($baby-powder);
  }

  &-skills {
    @extend %resume-shared;
    background-color: $saffron;
    color: set-text-color($saffron);

    div > div {
      margin: 0 auto;
      width: 95%;
      max-width: calc(#{$website-width * 0.5});
    }
  }

  &-side-gig {
    @extend %resume-shared;
    @extend %work-shared;
    background-color: $indian-red;
    color: $light-text-color;
  }

  &-industry-experience {
    @extend %resume-shared;
    @extend %icon-grid-shared;
    background-color: $black-coffee;
    color: $light-text-color;
  }

  &-education {
    @extend %resume-shared;
    background-color: $olive-drab-3;
    color: $light-text-color;
    h2 {
      margin-bottom: 0;
      text-align: center;
    }

    h2 + p {
      margin-bottom: 30px;
      text-align: center;
    }

    h3 {
      margin-top: 15px;
    }

    p {
      text-align: left;
    }
  }

  &-spare-time {
    @extend %resume-shared;
    @extend %icon-grid-shared;

    background-color: $indian-red;
    color: $light-text-color;
  }

  &-personal-motto {
    @extend %resume-shared;
    background-color: $saffron;
    color: $dark-text-color;

    blockquote {
      font-size: 1.5rem;
      font-style: italic;
    }
  }
  &-connect {
    @extend %resume-shared;
    background-color: $black-coffee;
    color: set-text-color($black-coffee);
    .connect {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      text-align: center;

      a {
        color: #fff;
      }
    }
  }
}

// Contact Page
#contact {
  &-a,
  &-b {
    .contact-info {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      text-align: center;
    }

    .fas,
    .fab {
      color: $icon-color;
    }

    a {
      color: $font-color;
    }
  }

  &-c {
    h1 {
      text-align: center;
      font-size: 4rem;
    }
  }
}

// Case

#case {
  // Cases A
  &-a {
    .about-case {
      margin-top: 3rem;
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(4, 1fr);

      .fas {
        font-size: 2rem;
        color: $main-color;
        padding-bottom: 0.4rem;
      }
    }
  }

  // Case B
  &-b {
    .img-frame,
    .case-image {
      box-sizing: border-box;
      width: 100%;
      border: 0.7rem solid white;
      margin: 0 0 20px 0;
      box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
    }
    .img-frame > img {
      border: none;
      box-shadow: none;
      margin: 0;
    }
  }
}

// Cases sections

// Footer
#main-footer {
  background: $black-coffee;
  color: #fff;
  height: 5rem;

  .footer-content {
    display: flex;
    justify-content: space-between;
    height: 5rem;
    align-items: center;

    .social .fab {
      margin-right: 1rem;
      border: 2px #fff solid;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      line-height: 20px;
      text-align: center;
      padding: 0.5rem;

      &:hover {
        background: $main-color;
      }
    }
  }
}

// Import Media Queries
@import 'media';
